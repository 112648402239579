var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);

/** The references object of all modules needed for components initialization */
var references = {
    /** Page module references */
    'account' : require('./pages/account'),
    'cart' : require('./pages/cart'),
    'checkout' : require('./pages/checkout'),
    'compare' : require('./../../../app_storefront_core/cartridge/js/pages/compare'),
    'product' : require('./pages/product'),
    'registry' : require('./pages/registry'),
    'search' : require('./pages/search'),
    'wishlist' : require('./pages/wishlist'),
    'storelocator' : require('./../../../app_storefront_core/cartridge/js/pages/storelocator'),
    'storefront' : require('./../../../app_storefront_core/cartridge/js/pages/storefront'),
    'error' : require('./pages/error'),
    'orderconfirmation': require('./pages/orderconfirmation'),
    'carousel' : require('./carousel'),
    'currency-switch' : require('./currency-switch'),
    'page' : require('./page'),

    /** Core level components */
    'countries' : require('./../../../app_storefront_core/cartridge/js/countries'),
    'minicart' : require('./minicart'),
    'tooltip' : require('./../../../app_storefront_core/cartridge/js/tooltip'),
    'validator' : require('./../../../app_storefront_core/cartridge/js/validator'),
    'captcha' : require('./../../../app_storefront_core/cartridge/js/captcha'),

    /** Components from extension cartridge */
    'product-actions' : require('./pages/product/product-actions'),
    'layout' : require('./layout'),
    'stickykit' : require('./stickykit'),
    'stickyheader' : require('./stickyheader'),
    'common-elements' : require('./common-elements'),
    'search-ext' : require('./search-ext'),
    'back-to-top' : require('./back-to-top'),
    'product-tile' : require('./../../../app_storefront_core/cartridge/js/product-tile'),
    'quantity' : require('./pages/product/quantity'),
    'detailsTabs' : require('./pages/product/detailsTabs'),
    'productimages' : require('./pages/product/images'),
    'contentSlot': require('./components/contentSlot'),
    'productsearch' : require('./productsearch'),
    'zoom' : require('./pages/product/zoom'),
    'productinfoaccordion': require('./pages/product/productinfoaccordion'),
    'navigation' : require('./pages/product/navigation'),
    'productset' : require('./pages/product/productSet'),
    'variant' : require('./pages/product/variant'),
    'refinements' : require('./refinements'),
    'quickview' : require('./quickview'),
    'google-maps-storelocator' : require('./storelocator/google-maps-storelocator'),
    'google-maps-storedetails' : require('./storelocator/google-maps-storedetails'),
    'storedetails' : require('./pages/storedetails'),
    'personal-details': require('./pages/account/personal-details'),
    'shipping-methods' : require('./pages/checkout/shipping-methods'),
    'vippoints' : require('./pages/checkout/vippoints'),
    'giftcard' : require('./pages/checkout/giftcard'),
    'paypal' : require('./pages/checkout/paypal'),
    'size-preference': require('./pages/account/size-preference'),
    'address-book': require('./pages/account/address-book'),
    'news-subscription': require('./pages/account/news-subscription'),
    'delivery-to-store-postcode' : require('./pages/checkout/delivery-to-store-postcode'),
    'annual-delivery-pass' : require('./pages/product/annual-delivery-pass'),
    'nominated-day-delivery' : require('./pages/checkout/nominated-day-delivery'),
    'credit-card-validator': require('./pages/checkout/credit-card-validator'),
    'states': require('./pages/checkout/states'),
    'confirmation-register': require('./pages/checkout/confirmation-register'),
    'google-maps-mainpage' : require('./storelocator/google-maps-mainpage'),
    'google-analytics' : require('./analytics/google/ecommerce'),
    'modal' : require('./pages/modal'),
    'addtobagmobile': require('./addtobagmobile'),
    'gdpr': require('./pages/gdpr'),
    'braquiz': require('./pages/braquiz'),
    'annualdeliverypasslandingpage': require('./pages/product/annualdeliverypasslandingpage'),
    'dynamicyield': require('./dynamicYieldCustom'),
    'addtocart': require('./pages/product/addtocart'),
    /** 3rd-party integration modules */
    'mention-me' : require('./analytics/mm/mentionme'),

    /** Modules **/
    'subscription' : require('./module/subscription'),
    'dcstorm' : require('./module/dcstorm'),
    'gtm': require('./analytics/gtm/index')
};

var checkoutConstants = require('./pages/checkout/constants');

/**
 * The components initialization configuration object
 *
 * @example New "Page" configuration
 *  var configuration = {
 *      //...
 *      'newpage' : {
 *          'enabled' : true,
 *          'options' : {},
 *          'components' : {
 *              'pagination' : {
 *                  'enabled' : false,
 *                  'options' : {}
 *              }
 *          }
 *      }
 *      //...
 *  }
 */
var configuration = {
    'global' : {
        'components': {
            'google-analytics': {},
            'dynamicyield': {},
            'common-elements' : {},
            'search-ext' : {},
            'layout' : {},
            'page' : {},
            'minicart' : {},
            'currency-switch': {},
            'carousel' : {
                'options' : {
                    'paginationTouchEnabled' : !require('./layout').isMobile()
                }
            },
            'stickykit' : {
                'options' : {
                    'container' : '.js-sticky_header'
                }
            },
            'stickyheader' : {},
            'tooltip' : {},
            'contentSlot' : {},
            'validator' : {},
            'captcha' : {},
            'back-to-top' : {},
            'quickview': {},
            'variant': {},
            'productset': {},
            'quantity' : {},
            'subscription' : {
                'options' : {'form' : $('.js-footer-subscription')}
            },
            'dcstorm' : {
                'enabled' : true
            },
            'google-maps-mainpage': {},
            'giftcard': {},
            'modal' : {},
            'gtm': {},
            'addtocart': {
                'options': {
                    'ignorePages': ['product', 'cart', 'quickview', 'search']
                }
            },
            'productimages': {}
        }
    },
    'storefront': {},
    'error': {},
    'orderconfirmation': {
        'components': {
            'confirmation-register': {},
            'mention-me': {}
        }
    },
    'storelocator': {
        'components': {
            'google-maps-storelocator': {}
        }
    },
    'storedetails':{
        'components': {
            'google-maps-storedetails': {}
        }
    },
    'cart' : {
        'components' : {
            'product-tile' : {},
            'variant': {
                'options': {
                    'skipSizePref': true
                }
            },
            'delivery-to-store-postcode': {},
            'annual-delivery-pass': {},
            'nominated-day-delivery': {},
            'product-actions' : {
                'options': {
                    'allowedActions': {
                        'addtocart': true
                    }
                }
            },
            'productimages' : {
                'options': {
                }
            }
        }
    },
    'product' : {
        'components' : {
            'product-actions' : {
                'options': {
                    'allowedActions': {
                        'notifyme': true,
                        'addtocart': true
                    }
                }
            },
            'product-tile' : {},
            'productimages' : {
                'options': {
                    'updateSize': true,
                    'mode': 'pdp'
                }
            },
            'navigation': {},
            'zoom': {},
            'annual-delivery-pass': {},
            'productinfoaccordion': {},
            'detailsTabs': {}
        },
        'pages': {
            'quickview': {
                'components': {
                    'product-actions' : {
                        'options': {
                            'allowedActions': {
                                'notifyme': true,
                                'addtocart': true
                            }
                        }
                    }
                }
            }
        }
    },
    'search': {
        'components': {
            'productimages' : {
                'options': {
                }
            },
            'productsearch' : {},
            'refinements' : {},
            'product-actions' : {
                'options': {
                    'allowedActions': {
                        'notifyme': true,
                        'addtocart': true
                    }
                }
            }
        }
    },
    'account': {
        'components': {
            'personal-details': {},
            'size-preference': {},
            'address-book': {},
            'giftcard': {},
            'news-subscription': {
                'options' : {'form' : $('.js-newsletter-vip')}
            },
            'credit-card-validator': {},
            'dcstorm' : {
                 'enabled' : false
            },
            'annual-delivery-pass': {}
        }
    },
    'checkout': {
        'components': {
            'delivery-to-store-postcode': {},
            'annual-delivery-pass': {},
            'nominated-day-delivery': {},
            'dcstorm' : {
                 'enabled' : false
            }
        },
        'pages': {
            'shipping': {
                'components': {
                    'states': {
                        'options': {
                            'form': checkoutConstants.CLASS_SHIPPING_FORM,
                            'isDisabledBtn': true
                        }
                    }
                }
            },
            'billing': {
                'components': {
                    'vippoints': {},
                    'giftcard': {},
                    'credit-card-validator': {},
                    'paypal': {},
                    'states': {
                        'options': {
                            'form': checkoutConstants.CLASS_BILLING_FORM,
                            'isDisabledBtn': true
                        }
                    }
                }
            },
            'signin': {
                'components': {
                    'account': {}
                }
            }
        }
    },
    'wishlist': {
        'components': {
           'dcstorm' : {
               'enabled' : false
           },
           'account': {}
        },
        'pages': {
            'signin': {
                'components': {
                    'account': {}
                }
            }
        }
    },
    'braquiz' : {
                  'options' : {}
                },
    'gdpr': {
        'components': {
            'dcstorm' : {
                 'enabled' : false
            }
        }
    },
    'annualdeliverypasslandingpage':{
        'components': {
            'annual-delivery-pass': {}
        }
    }
};

module.exports = {
    'configuration' : configuration,
    'references' : references
};
