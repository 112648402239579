'use strict';

var giftcert = require('./../../../../app_storefront_core/cartridge/js/giftcert'),
    tooltip = require('./../../../../app_storefront_core/cartridge/js/tooltip'),
    util = require('./../util'),
    dialog = require('./../dialog'),
    page = require('./../page'),
    validator = require('./../../../../app_storefront_core/cartridge/js/validator'),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null),
    resources = (typeof window !== "undefined" ? window['Resources'] : typeof global !== "undefined" ? global['Resources'] : null),
    checkoutConfigs = require('./account/config'),
    sitePreferences = (typeof window !== "undefined" ? window['SitePreferences'] : typeof global !== "undefined" ? global['SitePreferences'] : null),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    Constants = require('./account/constants'),
    CssConstants = require('./../cssconstants'),
    states = require('./checkout/states'),
    progress = require('./../progress'),
    address = require('./checkout/address'),
    QRCode = require('qrcode'),
    appleWallet = require('./../appleWallet'),
    ajax = require('./../ajax'),
    layout = require('./../layout');

var $cache = {};

function initializeCache() {
    $cache.document = $(document);
}

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    var $form = $(Constants.CLASS_ADDRESS_FORM);
    $form.find('input[name="format"]').remove();
    tooltip.init();

    // Set default country
    $('.country').val("GB");

    $form.on('click', '.apply-button', function (e) {
        e.preventDefault();
        if (!$form.valid()) {
            return false;
        }
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var applyName = $form.find('.apply-button').attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if (typeof(data) !== 'string') {
                if (data.success) {
                    dialog.close();
                    page.refresh();
                } else {
                    var $addressIdField = $form.find('input[id$=addressid]');
                    $addressIdField
                        .removeClass(CssConstants.VALID)
                        .addClass(CssConstants.ERROR)
                        .parent().find('span.' + CssConstants.ERROR).remove();
                    $addressIdField.after('<span class="'+ CssConstants.ERROR + ' ' + Constants.CLASS_ADDRESS_NAME_EXIST_ERROR + '">' + resources.ADDRESS_NAME_EXIST_ERROR + '</span>');
                    return false;
                }
            } else {
                $('#dialog-container').html(data);
                account.init();
                tooltip.init();
            }
        });
    })
    .on('blur', 'input[id$=addressid]', function() {
        $(this).parent().find('.' + Constants.CLASS_ADDRESS_NAME_EXIST_ERROR).remove();
    })
    .on('click', '.cancel-button, .close-button', function (e) {
        e.preventDefault();
        dialog.close();
    })
    .on('click', '.delete-button', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(resources.CONFIRM_DELETE, resources.TITLE_ADDRESS))) {
            var url = util.appendParamsToUrl(urls.deleteAddress, {
                AddressID: $form.find('#addressid').val(),
                format: 'ajax'
            });
            $.ajax({
                url: url,
                method: 'POST',
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    dialog.close();
                    page.refresh();
                } else if (data.message.length > 0) {
                    window.alert(data.message);
                    return false;
                } else {
                    dialog.close();
                    page.refresh();
                }
            });
        }
    })
    .keypress(function(e) {
        if (e.keyCode == $.ui.keyCode.ENTER) {
            e.preventDefault();
            $(this).find('.apply-button').trigger('click');
        }
    });
    $(document).off('address.populated').on('address.populated', function(e) {
        $form.valid();
    });
    validator.init();
    $(document).trigger('address.accountreinit');
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder () {
    $('.order-items')
        .find('li.hidden:first')
        .prev('li')
        .append('<a class="toggle">View All</a>')
        .children('.toggle')
        .click(function () {
            $(this).parent().siblings('li.hidden').show();
            $(this).remove();
        });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $('#addresses');
    if (addresses.length === 0) { return; }

    addresses.on('click', Constants.CLASS_ADDRESS_CREATE + ' ,' + Constants.CLASS_ADDRESS_EDIT, function (e) {
        e.preventDefault();
        dialog.open({
            url: this.href,
            options: {
                dialogClass: 'b-address_book-popup',
                open: initializeAddressForm,
                close: function() {
                    $('.pcatext').hide();
                },
                width: 638
            },
            callback: function() {
                $(Constants.CLASS_ADDRESS_FORM).on('change', 'select[name$="_country"]', function() {
                    states.validateStateField(Constants.CLASS_ADDRESS_FORM);
                });

                address.init();
            }
        });
    }).on('click', '.js-delete', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(resources.CONFIRM_DELETE, resources.TITLE_ADDRESS))) {
            $.ajax({
                url: util.appendParamToURL($(this).attr('href'), 'format', 'ajax'),
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    page.redirect(urls.addressesList);
                } else if (data.message.length > 0) {
                    window.alert(data.message);
                } else {
                    page.refresh();
                }
            });
        }
    });
}

function initCardRequestEvents() {
    if ($(Constants.CLASS_CARDREQUEST_FORM).length) {
        $(Constants.CLASS_CARDREQUEST_FORM).on('change', 'select[name$="_country"]', function() {
            states.validateStateField(Constants.CLASS_CARDREQUEST_FORM);
        });
        address.init();
    }
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    $(Constants.CLASS_ADD_CARD).on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                width: 550,
                dialogClass: 'b-payments_list-popup',
                open: initializePaymentForm
            }
        });
    });

    var paymentList = $(Constants.CLASS_PAYMENTS_LIST);
    if (paymentList.length === 0) { return; }

    $('form[name="payment-remove"]').on('submit', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(resources.CONFIRM_DELETE, resources.TITLE_CREDITCARD))) {
            // override form submission in order to prevent refresh issues
            var button = $(this).find(Constants.CLASS_DELETE_CARD);
            $('<input/>').attr({
                type: 'hidden',
                name: button.attr('name'),
                value: button.attr('value') || 'delete card'
            }).appendTo($(this));
            var data = $(this).serialize();
            $.ajax({
                type: 'POST',
                url: $(this).attr('action'),
                data: data
            })
            .done(function () {
                page.redirect(urls.paymentsList);
            });
        }
    });
}
/**
 * @private
 * @function
 * @description init events for the loginPage
 */
function initLoginPage() {
    //o-auth binding for which icon is clicked
    $('.oAuthIcon').bind('click', function () {
        $('#OAuthProvider').val(this.id);
    });

    //toggle the value of the rememberme checkbox
    $('#dwfrm_login_rememberme').bind('change', function () {
        if ($('#dwfrm_login_rememberme').attr('checked')) {
            $('#rememberme').val('true');
        } else {
            $('#rememberme').val('false');
        }
    });

    $cache.document
        .on('click', Constants.CLASS_PASSWORD_RESET, function (e) {
            e.preventDefault();
            dialog.open({
                url: $(e.target).attr('href'),
                options: {
                    width: 500,
                    dialogClass: CssConstants.B_RESET_PASSWORD
                }
            });
        })
        .on('click', Constants.CLASS_REQUEST_PASSWORD_SEND_BUTTON, function (e) {
            validator.init();
            var $this = $(this),
                $requestPasswordForm = $this.parents('[name$="_requestpassword"]');
            if (!$requestPasswordForm.valid()) {
                return;
            }
            e.preventDefault();

            var data = $requestPasswordForm.serialize();
            // add form action to data
            data += '&' + $this.attr('name') + '=';
            // make sure the server knows this is an ajax request
            if (data.indexOf('ajax') === -1) {
                data += '&format=ajax';
            }
            $.ajax({
                type: 'POST',
                url: $requestPasswordForm.attr('action'),
                data: data,
                success: function (response) {
                    if (typeof response === 'object' &&
                            !response.success &&
                            response.error === 'CSRF Token Mismatch') {
                        page.redirect(Urls.csrffailed);
                    } else if (typeof response === 'string') {
                        dialog.$container.html(response);
                    }
                },
                failure: function () {
                    dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
                }
            });
        });
}


function initializePaymentForm() {
    var $creditCardForm = $('#CreditCardForm');
    $creditCardForm.validate();

    $creditCardForm.on('click', Constants.CLASS_PAYMENTS_LIST_POPUP_CANCEL_BUTTON, function (e) {
        e.preventDefault();
        dialog.close();
    })

    $creditCardForm.on('submit', function(e) {
        if(sitePreferences.WP_ENABLE_CLIENT_SIDE_ENCRYPTION) {
            var formValidator = $(this).data('validator');
            formValidator.resetForm();

            $(this).find('[name$="_encryptedData"]').val('');

            var data = {
                    cvc : '',
                    cardHolderName: $(this).find('input[name$="creditcard_owner"]').val(),
                    cardNumber: $(this).find('input[name*="creditcard_number"]').val(),
                    expiryMonth: ($(this).find('[name$="_month"]').val() < 10 ? '0' : '')  + $(this).find('[name$="_month"]').val(),
                    expiryYear: $(this).find('[name$="_year"]').val()
                 }

            Worldpay.setPublicKey(sitePreferences.WP_CSE_PUBLIC_KEY);
            var encryptedData = Worldpay.encrypt(data, function(errorCodes) {
                    var $field,
                        errorObject = {};

                    for (var i = 0; i < errorCodes.length; i++){
                        $field = $(checkoutConfigs.creditcarderrors[errorCodes[i]]);
                        errorObject[$field.prop('name')] =   resources["WORLDPAY_CC_ERROR_" + errorCodes[i]];
                    }

                    formValidator.showErrors(errorObject);
                });
            if (encryptedData) {
                $(this).find('input[type != hidden], select').val('').addClass('js-validate-ignore');
                $(this).find('[name$="_encryptedData"]').val(encryptedData);
            } else {
                return false;
            }
        }
    });

}

function initRegistrationForm() {
    $('#RegistrationForm').off('submit')
        .on('submit', function(e) {
            e.preventDefault();

            var $form = $(this);
            var validator = $form.validate();
            var validationResult = validator.form();

            if (!validationResult) {
                return;
            }

            var formData = $form.serialize();
            var submitName = $form.find('button[type$="submit"]').attr('name');
            var finalFormData = [formData, submitName + '=true'].join('&');

            progress.show();

            $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data: finalFormData,
                success: function(data, status, $XHR) {
                    if ($XHR.responseJSON && !$XHR.responseJSON.success) {
                        return document.location.href = $XHR.responseJSON.redirect;
                    }

                    progress.hide();

                    dialog.open({
                        html: data,
                        options: {
                            open: function() {
                                var layout = require('./../layout');
                                if (!layout.isDesktop()) {
                                    window.scrollTo(0, 0);
                                    layout.bodyLock.call(document.getElementsByTagName('body')[0]);
                                }
                            },
                            close: function() {
                                progress.show();
                                document.location.href = '/';
                            }
                        }
                    });
                }
            });
        });
}

function generateMembershipQrCode() {
    var $popUp = $('.js-membership-pop-up');
    var $qrCodePlaceholder = $popUp.find('.js-membership-qr-code-placeholder');

    var customerEmail = $qrCodePlaceholder.data('customerEmail');
    if (customerEmail) {
        var scale = layout.isMobile() ? 6 : 12;
        QRCode.toCanvas(customerEmail, {scale: scale}, function (error, canvas) {
            if (error) {
                throw error
            }

            $qrCodePlaceholder.append(canvas);
        })
    }
}

function initApplePayButton() {
    var $popUp = $('.js-membership-pop-up');
    var $applePayButton = $popUp.find('.js-open-in-apple-wallet');

    if ($applePayButton.length) {
        $applePayButton.on('click', function(e){
            e.preventDefault();
            var url = $($applePayButton).attr('data-link');
            ajax.getJson({
                url: url,
                dataType: "json",
                callback: function(data) {
                    window.passConfig = data;

                    appleWallet.init();
                }
            })

        })
    }
}

function initMembershipIdBtn() {
    $cache.document.on('click', '.js-membership-id-btn', function(e) {
        e.preventDefault();
        var body = document.getElementsByTagName('body')[0];

        progress.show();

        dialog.open({
            url: window.Urls.membershipPopUp,
            options: {
                dialogClass: 'b-membership-qr-code-popup',
                open: function() {
                    layout.bodyLock.call(body);
                    generateMembershipQrCode();
                    initApplePayButton();
                    $('.js-membership-pop-up').removeClass('hidden');
                    progress.hide();
                },
                close: function() {
                    layout.bodyUnlock.call(body);
                }
            }
        });
    });
}

function initAccount() {
    // activate selected account navigation link
    $(document).ready(function() {
        $('.l-account-sidebar .b-account-nav_link').each(function() {
            var $link = $(this);
            if ($link.attr('href').indexOf(window.location.pathname) > -1) {
                $link.addClass('b-account-nav_link--selected');
            }
        });
    });
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    initAccount();
    toggleFullOrder();
    initAddressEvents();
    initPaymentEvents();
    initLoginPage();
    initCardRequestEvents();
    initRegistrationForm();
    initMembershipIdBtn();
}

var account = {
    init: function () {
        initializeCache();
        initializeEvents();
        giftcert.init();
    },
    initCartLogin: function () {
        initializeCache();
        initLoginPage();
    }
};

module.exports = account;
