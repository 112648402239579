'use strict';

var bonusProductsView = require('./../bonus-products-view'),
    cartStoreInventory = require('./../../../../app_storefront_core/cartridge/js/storeinventory/cart'),
    account = require('./account'),
    quickview = require('./../quickview'),
    progress = require('./../progress'),
    util = require('./../util'),
    minicart = require('./../minicart'),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    shippingMethodsModel = require('./checkout/shipping-methods'),
    layout = require('./../layout'),
    Constants = require('./constants');

var $cache = {},
    removeItemEvent = false,
    shippingMethods,
    options = {
        shippingMethodList: Constants.CLASS_SHIPPING_METHOD_LIST,
        orderTotal: Constants.CLASS_ORDER_TOTAL,
        shippingFields: Constants.CLASS_SHIPPING_FIELDS
    };

function initializeCache() {
    $cache.document = $(document);
    $cache.window = $(window);
    $cache.deliveryThreshold = $(document).find(Constants.CLASS_DELIVERY_THRESHOLD);
    $cache.couponInput = $(document).find(Constants.SELECTOR_COUPON_INPUT);
}

function updateProductQuantityHandler(event) {
    event.stopImmediatePropagation();
    var $this = $(this),
        $container = $this.closest(Constants.CLASS_QUANTITY);

    if(!$container.length) return;

    var quantity = +$container.find('input').val(),
        url = $container.data('url');

    progress.show($container);
    updateProductQuantity(url, quantity);
}

function updateProductQuantity(url, quantity) {
    $.ajax({
        type: 'POST',
        url: util.appendParamsToUrl(url, {
            'Quantity': quantity,
            'countryCode': $('select[id$="_country"]').val()
        })
    }).done(function(response) {
        $(Constants.CLASS_CART_CONTENT).html(response);
        minicart.update();
        checkShippingMethods();
        initializeCache();
        updateKlarnaMessages();
        dynamicYield.callEvent('Sync cart');
        initDeliveryTheshold();
    });
}

function updateKlarnaMessages() {
    window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
    window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
}

function checkShippingMethods() {
    $(Constants.CLASS_CHECKOUTCART_BTN).prop({
        'disabled': $cache.document.find(Constants.ClASS_NO_SHIPPING_METHODS).length ? true : false
    });
}

function initializeShippingMethods() {
    shippingMethods = shippingMethodsModel.init({
        'shippingMethodList': options.shippingMethodList,
        'refreshBlock': options.orderTotal,
        'refreshUrl': Urls.totalsRefreshURL,
        'form': options.shippingFields,
        'isCart': true
    });
}

function initializeEvents() {
    $cache.document
    .on('click', Constants.CLASS_EDIT_DETAILS, function(e) {
        e.preventDefault();

        minicart.close(0);

        quickview.show({
            url: e.target.href,
            source: 'cart'
        });
    })
    .on('click', Constants.CLASS_BONUS_ITEM, function(e) {
        e.preventDefault();
        bonusProductsView.show(this.href);
    })
    .on('change', 'select[name$="_country"]', function() {
        shippingMethods.updateShippingMethodList();
        progress.show($(options.shippingMethodList));
    })
    .on('keydown', Constants.SELECTOR_COUPON_INPUT, function(e) {
        if (e.which === 13 && $(this).val().length === 0) {
            return false;
        }
    })
    .on('click', 'button[name$="deleteProduct"]', function(e) {
        if (removeItemEvent) {
            e.preventDefault();
        } else {
            removeItemEvent = true;
        }
    })
    .on('click', Constants.CLASS_CART_ADD_COUPON, function(e) {
        return $cache.couponInput.val().length > 0;
    })
    .on('change', Constants.CLASS_QTY_CURRENT, updateProductQuantityHandler)
    .on('keydown', Constants.CLASS_QTY_CURRENT, function(e) {
        if(e.keyCode === 13) {
            e.preventDefault();
            $(this).trigger('change');
        }
    })
    .on('check.shippingmethods', 'select[name$="_country"]', checkShippingMethods)
    .on('submit', Constants.CLASS_CHECKOUT_FORM, function(e) {
        if(!shippingMethodsModel.validateDeliveryToStore()) {
            e.preventDefault();
        }
    })
    .on('click', Constants.CLASS_GE_SHOW_POPUP, function(e) {
      if (window.gle) {
        e.preventDefault();
        window.gle("ShippingSwitcher", "show");
      }
    })
    .on('click', '.js-remove-items-link', function(e) {
        e.preventDefault();

        var $outOfStock = $('.m-out_of_stock');

        if ($outOfStock.length) {
            var offset = $outOfStock.first().offset();
            var options = {
                top: offset.top,
                left: offset.left,
                behavior: 'smooth'
            };

            if (layout.isMobile()) {
                var $slotHeader = $('.b-upper_slot_header');
                var $LHeader = $('.l-header');

                options.top -= (($slotHeader.length && $slotHeader.height()) + ($LHeader.length && $LHeader.height()));
            } else {
                var $mainHeader = $('#main-header');

                options.top -= ($mainHeader && $mainHeader.height());
            }

            window.scrollTo(options);
        }
    })
    .on('addAnnualDeliveryPass', function(event, response) {
        $(Constants.CLASS_CART_CONTENT).html(response);
        minicart.update();
        checkShippingMethods();
        initializeCache();
        shippingMethods.updateShippingMethodList();
    });

    initDeliveryTheshold();
    initializeShippingMethods();
    checkShippingMethods();
}

function initDeliveryTheshold() {
    if ($cache.deliveryThreshold.length) {
        var deliveryThresholdWidth = (Math.trunc($cache.deliveryThreshold.data('deliveryPriceLevel')) * 100) / Math.trunc($cache.deliveryThreshold.data('deliveryFreePriceLevel')) + '%';

        $cache.deliveryThreshold.find('.js-delivery-indicator').css('width', deliveryThresholdWidth);
        $cache.deliveryThreshold.removeClass('g-hidden');
    }
}

var cart = {
    init : function() {
        initializeCache();
        initializeEvents();
        if (SitePreferences.STORE_PICKUP) {
            cartStoreInventory.init();
        }
        account.initCartLogin();
        shippingMethods.updateShippingMethodList();
    }
}

module.exports = cart;
