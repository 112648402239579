'use strict';

function getNaizfitData() {
    
    if (!SitePreferences.NAIZFIT_ENABLED) {
        return;
    }
    setTimeout(async function() {
        const hash = await callextractData();
        const orderNo = $('.js-order-details .value').html();
        const dataToSent = {
            hash: hash,
            orderNo: orderNo
        };

        callNaizfitEndpoint(dataToSent);

    }, 300)
    return;
}

async function callextractData() {
    try {
        const hash = await window.nfsfo.extractConsumerData().then(function(data){return data.hash});
        return hash;
    } catch (err) {
        console.log("ERROR window.nfsfo :" + err);
        return null;
    }
}


function callNaizfitEndpoint(data) {
    var url = window.Urls.naizfit;
    $.ajax({
        type: 'POST',
        url: url,
        data: data
    }).done(function(response) {
        return response;
    });
}

function setTimeoutToRedirectFromPage() {
    // 5 min
    var time = 5 * 60000;

    setTimeout(function() {
        if (window.Urls && window.Urls.homepage) {
            window.location.href = window.Urls.homepage;
        }
    }, time);
}

exports.init = function () {
    setTimeoutToRedirectFromPage();
    getNaizfitData();
};
